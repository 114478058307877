import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { BasketService } from '../services/basket.service';

@Injectable({
  providedIn: 'root'
})
export class BoxGuardGuard implements CanActivate {

  constructor(private router: Router, private basketService: BasketService, private authService: AuthService){
  } 
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if(this.authService.boxConnected){
        return true
      }
      else{
        this.router.navigate(['/'])
        return false
      } 
  }

  

}
