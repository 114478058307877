
<section
    class="section_orders_tracking"
    [ngClass]="'animate__animated animate__slideInRight duration-0-3'"
>
    <app-header [title]='title'></app-header>

    <div class="section_orders_tracking-traking_card"
        *ngFor="let order of listOrders"
    >
        <div class="section_orders_tracking-traking_card-price"
        [ngStyle]="{'background': order.sender ? '#029640' : '#ee1c3a' }"
        >{{order.total_ttc | number : '1.2-2' }} Dhs</div>
        <div class="section_orders_tracking-traking_card-img">
            <img *ngIf="order.sender == 1" src="../../../assets/images/sender.svg" alt="">
            <img *ngIf="order.sender == 0" src="../../../assets/images/receiver.svg" alt="">
        </div>
        <div class="section_orders_tracking-traking_card-details">

            <div 
                class="section_orders_tracking-traking_card-details-store"
                [ngStyle]="{'color': order.sender ? '#029640' : '#ee1c3a' }"
                *ngIf="order.sender == 1"
            ><span class="section_orders_tracking-traking_card-details-store-to">à :</span>{{ order.store_name }}</div>
            <div 
                class="section_orders_tracking-traking_card-details-store"
                [ngStyle]="{'color': order.sender ? '#029640' : '#ee1c3a' }"
                *ngIf="order.sender == 0"
            ><span class="section_orders_tracking-traking_card-details-store-from">de :</span>{{ order.box_name }}</div>

            <div class="section_orders_tracking-traking_card-details-status" *ngIf="order.sender == 0 && order.status == 0">Commande à valider</div>
            <div class="section_orders_tracking-traking_card-details-status" *ngIf="order.sender == 1 && order.status == 0">En attente de validation</div>
            <div class="section_orders_tracking-traking_card-details-status" *ngIf="order.sender == 1 && order.status == 1">En cours de livraison</div>
            <div class="section_orders_tracking-traking_card-details-status" *ngIf="(order.sender == 1 || order.sender == 0) && order.status == 2">Commande livrée</div>

            <div class="section_orders_tracking-traking_card-details-details">{{ order.details }}</div>
            <div class="section_orders_tracking-traking_card-details-date_details_button">
                <div class="section_orders_tracking-traking_card-details-date_details_button-date">{{ order.date }}</div>
                <div class="section_orders_tracking-traking_card-details-date_details_button-details_button">
                    <div class="section_orders_tracking-traking_card-details-date_details_button-details_button-button btn"
                        (click)="getOrderDetailsByOrderId(order.order_id)"
                        [ngStyle]="{'background': order.sender ? '#029640' : '#ee1c3a' }"
                    >Details</div>
                </div>
            </div>
        </div>
    </div>
    

</section>


