import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-orders-tracking',
  templateUrl: './orders-tracking.component.html',
  styleUrls: ['./orders-tracking.component.scss']
})
export class OrdersTrackingComponent implements OnInit {

  title = "Mes commandes"

  caisseId = window.localStorage.getItem('caisse');
  loggedInUser = JSON.parse(window.localStorage.getItem('LoggedInUser'));
  userId = this.loggedInUser.id_user;

  listOrders = []
 
  constructor(private http: HttpService, private router: Router) { }

  ngOnInit(): void {

    this.http.getOrdersByUser(this.userId, this.caisseId).subscribe( listOfOrders => {
      this.listOrders = listOfOrders
    })
    
  }

  getOrderDetailsByOrderId(orderId){
    this.router.navigate(['/order-details', orderId])
  }

}
