import { Injectable } from '@angular/core';
import { Basket } from '../models/basket'

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  basket:Basket = {
    items: [],
  }

  selectedProducts

  constructor() {
   }


  updateBasketStoredInLocalStorage(){
    localStorage.setItem("selectedProducts", JSON.stringify(this.basket.items));
  }

  initializeLocalStorageWithEmptyBasket(){
    let obj:Basket = {
      items: [],
    }
    localStorage.setItem("selectedProducts",JSON.stringify(obj.items)); 
  }

  getSelectedProductFromLocalStorage(){
    return JSON.parse(localStorage.getItem("selectedProducts"))
  }

  clearLocalStorage(){
    window.localStorage.clear();
  }

  removeItem(index){
    this.basket.items = this.basket.items.filter( (item, i) => i != index )
    this.updateBasketStoredInLocalStorage()
  }

  async addItem(item: any) {
      this.basket.items.push({ ...item });
      this.updateBasketStoredInLocalStorage()
  }

  inializeBasket(){
    this.basket = {
      items: [],
    }
  }
}
