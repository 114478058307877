import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationService } from '../../services/navigation.service'

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  @Input() title: string;

  constructor(
    private navigation: NavigationService,
    private authService: AuthService
  ) { 
  }

  ngOnInit(): void {
  }

  goBack(){
    this.navigation.back()
  }

  logout(){
    this.authService.logout()
  }

  

}
