

<section
    class="section_dashboard"
    [ngClass]="'animate__animated animate__slideInRight duration-0-3'"
>

    <app-header [title]='title'></app-header>

    <div class="section_dashboard-dashboard_card btn" (click)="goToNewOrderPage()">
        <img src="../../../assets/images/new-purchase-order.svg" alt="">
        <span>Créer un bon de commande</span>
    </div>
    
    <div class="section_dashboard-dashboard_card btn" (click)="goToOrdersTrackingPage()">
        <img src="../../../assets/images/traking-purchase-order.svg" alt="">
        <span>Gérer mes bons de commande</span>
    </div>    
</section>
 