import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  title = "tableau de bord"

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  

  goToOrdersTrackingPage(){
    this.router.navigate(['/orders-tracking'])
  }

  goToNewOrderPage(){
    this.router.navigate(['/new-order'])
  }

}
