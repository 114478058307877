import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { ChooseUserComponent } from './components/choose-user/choose-user.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CreatePurchaseOrderComponent } from './components/create-purchase-order/create-purchase-order.component';
import { ManageMyPurchaseOrdersComponent } from './components/manage-my-purchase-orders/manage-my-purchase-orders.component';
import { PopupAddProductComponent } from './components/popup-add-product/popup-add-product.component';
import { OrdersTrackingComponent } from './components/orders-tracking/orders-tracking.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { PopupOrderIsDeliveredComponent } from './components/popup-order-is-delivered/popup-order-is-delivered.component';
import { PopupAcceptAnOrderComponent } from './components/popup-accept-an-order/popup-accept-an-order.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChooseUserComponent,
    AppHeaderComponent,
    DashboardComponent,
    CreatePurchaseOrderComponent,
    ManageMyPurchaseOrdersComponent,
    PopupAddProductComponent,
    OrdersTrackingComponent,
    OrderDetailsComponent,
    PopupOrderIsDeliveredComponent,
    PopupAcceptAnOrderComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatDialogModule,
    HttpClientModule,
    MatSelectModule,
    MatSnackBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
