import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent } from './components/login/login.component'
import {ChooseUserComponent } from './components/choose-user/choose-user.component'
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CreatePurchaseOrderComponent } from './components/create-purchase-order/create-purchase-order.component';
import { ManageMyPurchaseOrdersComponent } from './components/manage-my-purchase-orders/manage-my-purchase-orders.component';
import { OrdersTrackingComponent } from './components/orders-tracking/orders-tracking.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { BoxGuardGuard } from '../app/guards/box-guard.guard';
import { UserGuardGuard } from '../app/guards/user-guard.guard'

const routes: Routes = [
  { path: "" , component: LoginComponent },
  { path: "choose-user" , component: ChooseUserComponent, canActivate: [BoxGuardGuard]},
  { path: "dashboard" , component: DashboardComponent , canActivate: [UserGuardGuard]},
  { path: "my-orders" , component: ManageMyPurchaseOrdersComponent , canActivate: [UserGuardGuard]},
  { path: "new-order" , component: CreatePurchaseOrderComponent , canActivate: [UserGuardGuard]},
  { path: "orders-tracking" , component: OrdersTrackingComponent, canActivate: [UserGuardGuard]},
  { path: "order-details/:orderId" , component: OrderDetailsComponent, canActivate: [UserGuardGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
