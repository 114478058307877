<section
    class="section_login"
    [ngStyle]="{
        'width': screenWidthInPixels, 
        'height': screenHeightInPixels
              }"
>
              
    <div 
        class="section_login-shape"
        data-aos="fade-down"
        data-aos-duration="500"
    >

        <div class="section_login-shape-logo animate__animated animate__backInDown animate__delay-1.2s">
            APPROVISIONNEMENT
        </div>
        
        <div class="section_login-shape-login_form">

            <div class="section_login-shape-login_form-title"
                    data-aos="fade-left"
                    data-aos-delay="500"
                    data-aos-duration="500"
            ><span>Se</span> connecter</div>
            <div class="section_login-shape-login_form-input">
            <form [formGroup]="loginForm">
                <div class="section_login-shape-login_form-input-username_field"
                    data-aos="fade-left"
                    data-aos-delay="650"
                    data-aos-duration="500"
                >
                    <mat-icon>person</mat-icon>
                    <mat-form-field appearance="fill">
                        <mat-label>Username</mat-label>
                        <input formControlName="username" matInput >
                    </mat-form-field>
                </div>

                <div class="section_login-shape-login_form-input-password_field"
                    data-aos="fade-left"
                    data-aos-delay="700"
                    data-aos-duration="500"
                >
                    <mat-icon>lock</mat-icon>
                    <mat-form-field appearance="fill">
                        <mat-label>Password</mat-label>
                        <input formControlName="password" matInput [type]="hide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon class="section_login-shape-login_form-input-password_field-icon_visibility">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                      </mat-form-field>
                </div> 
            </form>
            </div>

        </div>



    </div>

    <div class="section_login-shape-powered_by animate__animated animate__delay-0.8s animate__zoomIn" 
    >
        <span class="section_login-shape-powered_by-text">Powered by</span>
        <img class="section_login-shape-powered_by-image" src="../../../assets/images/lacaisse.ma logo.svg" alt="">
    </div>

    
    <div class="section_login-button btn btn-success"
        [ngClass]="{'section_login-button-buttonLoginClicked': loginButtonClicked}"
        data-aos="fade-up"
        data-aos-duration="500"
        (click)="login()"
    >
        <img
            src="../../../assets/images/loader.svg" 
            alt="" 
            *ngIf="loginButtonClicked"
            >
        <span 
            class="animate__fadeIn animate__animated animate__delay-1s"
            *ngIf="!loginButtonClicked"
        >
            Se connecter</span>
    </div>
    
</section>
