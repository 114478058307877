import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { BasketService } from '../../services/basket.service'

@Component({
  selector: 'app-popup-add-product',
  templateUrl: './popup-add-product.component.html',
  styleUrls: ['./popup-add-product.component.scss']
})
export class PopupAddProductComponent implements OnInit {

  listOfProducts
  filteredCategories

  numberOfSelectedProduct = []

  searchField = ''

  

  constructor(
      @Inject(MAT_DIALOG_DATA) public products: any, 
      private basketSerive: BasketService, 
      private dialog: MatDialog,
      ) {
        console.log(products)
       }

  ngOnInit(): void {
    this.listOfProducts = this.products

    for (let index = 0; index < this.listOfProducts.length; index++) {

      this.listOfProducts[index].showProducts = false

      for ( let j = 0; j < this.listOfProducts[index].products.length; j++ ){
        this.listOfProducts[index].products[j].count = 0
      }

    }
  }

  filterCategories() {
    const searchTerm = this.searchField.toLowerCase().trim();
  
    if (searchTerm === '') {
      // If the search field is empty, reset all categories and set 'showProducts' to false
      this.filteredCategories = this.products.map(category => ({
        ...category,
        showProducts: false // Set 'showProducts' to false for all categories
      }));
    } else {
      this.listOfProducts = this.products
        .map(category => {
          // Filter products within each category based on the search term
          const filteredProducts = category.products.filter(product =>
            product.designation.toLowerCase().includes(searchTerm)
          );
  
          // Return a new category object with 'showProducts' set to true if it contains matching products
          return {
            ...category,
            products: filteredProducts,
            showProducts: filteredProducts.length > 0 // Set 'showProducts' to true if products match
          };
        })
        // Only include categories that have matching products
        .filter(category => category.products.length > 0);
    }
  }

  highlightText(text: string): string {
    const searchTerm = this.searchField.toLowerCase().trim();
    
    if (!searchTerm) {
      return text; // Return the original text if search field is empty
    }

    // Create a regular expression to find the search term (case-insensitive)
    const regex = new RegExp(`(${searchTerm})`, 'gi');

    // Replace the matched term with highlighted HTML
    return text.replace(regex, `<span class="highlight">$1</span>`)
  }
  
 

  numberOfSelectedProductsForEachCategory(){
    for (let i = 0; i < this.listOfProducts.length; i++) {
      let count = 0
      for (let j = 0; j < this.listOfProducts[i].products.length; j++) {
        if(this.listOfProducts[i].products[j].count > 0) count++
      }
      this.numberOfSelectedProduct[i]=count
    }
  }

  showMore(i){
    /*
    for (let index = 0; index < this.listOfProducts.length; index++) {
      this.listOfProducts[index].showProducts = false
    }
    */
    this.listOfProducts[i].showProducts = !this.listOfProducts[i].showProducts
  }

  showLess(i){
    this.listOfProducts[i].showProducts = !this.listOfProducts[i].showProducts
  }


  /*  
        this.listOfProducts[index] = {
          "id": this.listOfProducts[index].id,
          "count":this.listOfProducts[index].count,
          "designation": this.listOfProducts[index].designation,
          "valid": false,
          "edit": true
        }
    */

  /*
  async addToBasket(index){
    await this.basketSerive.addItem(this.filteredProductsList[index])
    await this.dialog.closeAll()
    await this.filteredProductsList.forEach(product => {
      product.count = 1  
    });
  }
  */

  addToBasket(){
    for (let i = 0; i < this.listOfProducts.length; i++) {
      for ( let j = 0; j < this.listOfProducts[i].products.length; j++ ){
        if(this.listOfProducts[i].products[j].count != 0 && this.listOfProducts[i].products[j].count != null)
         this.basketSerive.addItem(this.listOfProducts[i].products[j])  
      }
    }
    this.dialog.closeAll()
  }

}

