
<section
    class="section_choose_user"
    [ngClass]="'animate__animated animate__slideInRight duration-0-3'"
>
    <app-header [title]='title'></app-header>

    <div 
        *ngFor="let user of listUsers ;index as i"
        class="section_choose_user-user_card">

        <div class="section_choose_user-user_card-first_line"
            [ngClass]="{'selected': this.keyboardForRequestedUser[i]}"
        >
            <img src="../../../assets/images/profile1.png" alt="">
            <div class="section_choose_user-user_card-first_line-text">
                <span class="section_choose_user-user_card-first_line-text-first_line">Nom d'utilisateur</span>
                <span class="section_choose_user-user_card-first_line-text-second_line">{{ user.nom }}</span>
            </div>
            <mat-icon 
            (click)="showKeypad(i)" class="material-symbols-outlined">
                lock
            </mat-icon>
        </div>

        <div 
            class="section_choose_user-user_card-second_line"
            *ngIf="keyboardForRequestedUser[i]"
        >

            <div class="section_choose_user-user_card-second_line-numeric_keypad">

                <div class="section_choose_user-user_card-second_line-numeric_keypad-lines">
                    <form [formGroup]="userPassword">
                        <div>
                            <input type="password" formControlName="password" [(ngModel)]="password" matInput readonly>
                        </div>
                    </form>
                </div>

                <div class="section_choose_user-user_card-second_line-numeric_keypad-lines">
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="add('1')">1</div>
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="add('2')">2</div>
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="add('3')">3</div>
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="add('4')">4</div>
                </div>

                <div class="section_choose_user-user_card-second_line-numeric_keypad-lines">
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="add('5')">5</div>
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="add('6')">6</div>
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="add('7')">7</div>
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="add('8')">8</div>
                </div>

                <div class="section_choose_user-user_card-second_line-numeric_keypad-lines">
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="reset()" >x</div>
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="add('9')">9</div>
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="add('0')">0</div>
                    <div class="section_choose_user-user_card-second_line-numeric_keypad-lines-bouton btn btn-success" (click)="deleteLastCharacter()"><-</div>
                </div>
            </div>

            <div class="section_choose_user-user_card-second_line-validation_button">
                <div
                    class="section_choose_user-user_card-second_line-validation_button-bouton btn btn-success"
                    (click)="login(i)"
                >
                    <img src="../../../assets/images/Login.svg" alt="">
                </div>
            </div>
            
        </div>
    </div>  
</section>
