import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-choose-user',
  templateUrl: './choose-user.component.html',
  styleUrls: ['./choose-user.component.scss']
})
export class ChooseUserComponent implements OnInit {

  password = ""
  sectionChooseUser;

  title = "liste des utilisateurs"

  userPassword = new FormGroup({
    password : new FormControl("", Validators.required)
  })

  listUsers = []
  keyboardForRequestedUser: boolean[] = [];

  tokenApi = window.localStorage.getItem('token');
  caisseId = window.localStorage.getItem('caisse');

  constructor(
    private httpService: HttpService,
    private router: Router,
    private snackBar: MatSnackBar,
    private element: ElementRef,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    if(this.tokenApi && this.caisseId){
      this.httpService.getUsers(this.tokenApi, this.caisseId).subscribe(res => {
        this.listUsers = res
      })
    }

  }

  add(number){
    if(this.password.length < 4)
    this.password = this.password + number + ""
    
  }

  reset(){
    this.password = ""
  }

  

  deleteLastCharacter(){
    if(this.userPassword.value.password.length>0) this.userPassword.value.password = this.userPassword.value.password.slice(0,-1)
  }

  showKeypad(indexOfUser : number){

    this.password = ""

    let length = this.listUsers.length

    for (let index = 0; index < length; index++) {
      
      if(this.keyboardForRequestedUser.length < length) this.keyboardForRequestedUser.push(false)

      for (let index = 0; index < length; index++) {
        if(this.keyboardForRequestedUser[index] == true)
        this.keyboardForRequestedUser.splice(index,1,false)
        this.password = ""
      }

    }
    this.keyboardForRequestedUser.splice(indexOfUser,1,true)

  }

  login(indexOfUser : number){
    if(this.password == ""){
        this.snackBar.open("Merci de saisir le mot de passe !","Fermer", {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 2000,
        });
    }else{
        if ( this.listUsers[indexOfUser].password == this.password){

          this.sectionChooseUser = this.element.nativeElement.querySelector('.section_choose_user')
          this.sectionChooseUser.classList.add('animate__animated')
          this.sectionChooseUser.classList.add('animate__slideOutLeft')
          this.sectionChooseUser.classList.add('duration')

          window.localStorage.setItem('LoggedInUser', JSON.stringify(this.listUsers[indexOfUser]));
          this.authService.userConnected = true

          this.password = ""
          
          setTimeout(()=>{
            this.router.navigate(['/dashboard'])
          },200)
          
        }else{
          this.password = ""
          this.snackBar.open("Mot de passe incorrect !","Fermer", {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
          });
        }
    }
    
  }



}
