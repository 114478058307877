import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token        = window.localStorage.getItem('token');
  caisse       = window.localStorage.getItem('caisse');
  name         = window.localStorage.getItem('name');
  loggedInUser = window.localStorage.getItem('LoggedInUser');

  boxConnected = false
  userConnected = false
  
  constructor(private router: Router) {
    this.boxIsConnected()
    this.userIsConnected()
  }

  boxIsConnected(){
    if (this.token && this.caisse && this.name){
      this.boxConnected = true
    }else{
      this.boxConnected = false
    }
  }

  userIsConnected(){
    if (this.token && this.caisse && this.name && this.loggedInUser){
      this.userConnected = true
    }else{
      this.userConnected = false
    }
  }

  logout(){
    if(this.router.url == '/choose-user'){
      window.localStorage.clear()
      this.boxConnected = false
      this.userConnected = false
      this.router.navigate(['/'])
    }else{
      window.localStorage.removeItem('LoggedInUser')
      this.userConnected = false
      this.router.navigate(['/choose-user'])
    }
  }
}
