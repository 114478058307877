import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PopupOrderIsDeliveredComponent } from '../popup-order-is-delivered/popup-order-is-delivered.component';
import { PopupAcceptAnOrderComponent } from '../popup-accept-an-order/popup-accept-an-order.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  title = "détails de la commande"

  constructor(private route: ActivatedRoute, private http: HttpService, private dialog: MatDialog) { }

  orderDetails;
  orderId;
  caisseId = window.localStorage.getItem('caisse');

  ngOnInit(): void {
    this.route.params.subscribe(params =>{
      this.orderId = params.orderId
      this.http.getOrderDetailsByOrderId(params.orderId, this.caisseId).subscribe(orderDetails =>{
        this.orderDetails = orderDetails
      })
    })
  }

 

  myOrderIsDelivered(){
    let orderDetails = {
      "orderId": this.orderId,
      "products" : this.orderDetails.products
    }
    this.dialog.open( PopupOrderIsDeliveredComponent, 
      {
      width: '90vw',
      maxWidth: '90vw',
      height: '90%',
      panelClass: 'custom-modal',
      data: orderDetails,
    });
  }

  acceptTheOrder(){
    let orderDetails = {
      "orderId": this.orderId,
      "products" : this.orderDetails.products
    }
    this.dialog.open( PopupAcceptAnOrderComponent, 
      {
      width: '90vw',
      maxWidth: '90vw',
      height: '90%',
      panelClass: 'custom-modal',
      data: orderDetails,
    });
  }


  printOrderDetails(){
    let url = 'https://api-legacy.lacaisse.ma/createblbcommande.php?uuidd='+this.orderId+'&id_caisse='+this.caisseId
    window.open(url , '_blank');
  }

}
