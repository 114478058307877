<app-header [title]='title'></app-header>

<div class="backdrop" *ngIf="downloadProductsPending">
    Chargement ...
</div>

<div class="passed_order" *ngIf="stepsFinished[3]">
    <span>Votre commande a été transmise</span>
    <img src="../../../assets/images/man-ok.svg" alt="">
</div>

<form [formGroup]="newOrderForm">
    <div 
        [ngClass]="{'animate__animated animate__slideInRight duration-0-5': listSuppliers.length}"
        class="section_new_order" 
        *ngIf="listSuppliers.length > 0 && step == 1 && !stepsFinished[1]" 
        >
        <div class="section_new_order-choose_supplier">
            <div class="section_new_order-choose_supplier-step_title">
                <span class="section_new_order-choose_supplier-step_title-step_number">ETAPE 1 : </span>
                <span class="section_new_order-choose_supplier-step_title-step_order">Choisissez le fournisseur</span>
            </div>

            <mat-form-field appearance="outline">

                <input type="text"
                    placeholder="Coca ..."
                    aria-label="Number"
                    matInput
                    formControlName="supplier"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">

                <mat-option *ngFor="let option of filteredSuppliers | async" [value]="option.nom_fournisseur" (click)="getSelectedSupplier(option)">
                    {{option.nom_fournisseur}}
                </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <label
                style="
                    padding: 5px 0px 0px 0px;
                    font-size: 11px;
                    display: flex;
                "       
            >
                <input style="margin-right: 3px;margin-top: -2px;" type="checkbox" (change)="onCheckboxChange($event)" />
                Uniquement les produits de ce fournisseur
            </label>
              
        </div>        
    </div>

    <!-------------------------------------------------STEPS------------------------------------------------------------------>

    <div 
        class="section_new_order-step_one_finished" 
        *ngIf="stepsFinished[1]"
        [ngClass]="{'animate__animated animate__slideInRight duration-0-5': listSuppliers.length}"
    >
        <div class="section_new_order-step_one_finished-step_title">
            <span class="section_new_order-step_one_finished-step_title-step_number">ETAPE 1 : </span>
            <span class="section_new_order-step_one_finished-step_title-step_order">Choisissez le fournisseur</span>
        </div>
         
        <mat-icon class="material-symbols-outlined section_new_order-step_one_finished-icon">
            check_circle
        </mat-icon>
    </div>
    
    <!------------------------------------------------------------------------------------------------------------------------->

    <div 
        class="section_new_order-step_one_finished" 
        *ngIf="stepsFinished[2]"
        [ngClass]="{'animate__animated animate__slideInRight duration-0-5': listSuppliers.length}"
    >
        <div class="section_new_order-step_one_finished-step_title">
            <span class="section_new_order-step_one_finished-step_title-step_number">ETAPE 2 : </span>
            <span class="section_new_order-step_one_finished-step_title-step_order">Choisissez les produits désirés</span>
        </div>
         
        <mat-icon class="material-symbols-outlined section_new_order-step_one_finished-icon">
            check_circle
        </mat-icon>
    </div>

    <!------------------------------------------------------------------------------------------------------------------------->

    <div 
        class="section_new_order-step_one_finished" 
        *ngIf="stepsFinished[3]"
        [ngClass]="{'animate__animated animate__slideInRight duration-0-5': listSuppliers.length}"
        >
        <div class="section_new_order-step_one_finished-step_title">
            <span class="section_new_order-step_one_finished-step_title-step_number">ETAPE 3 : </span>
            <span class="section_new_order-step_one_finished-step_title-step_order">Choisissez une boutique</span>
        </div>
         
        <mat-icon class="material-symbols-outlined section_new_order-step_one_finished-icon">
            check_circle
        </mat-icon>
    </div>

    <!---------------------------------------------END STEPS------------------------------------------------------------------->

    <div 
        class="section_new_order" 
        *ngIf="listSuppliers.length > 0 && step == 2 && !stepsFinished[2]"
        [ngClass]="{'animate__animated animate__slideInRight duration-0-5': listSuppliers.length}"
        >
        <div class="section_new_order-choose_products">
            <div class="section_new_order-choose_products-step_title">
                <span class="section_new_order-choose_products-step_title-step_number">ETAPE 2 : </span>
                <span class="section_new_order-choose_products-step_title-step_order">Choisissez les produits désirés</span>
            </div>

            <div class="section_new_order-choose_products-add_btn btn" (click)="openAddProductPopup()">
                Ajouter un produit
            </div>

            <div class="section_new_order-choose_products-empty_cart" *ngIf="this.basketSerive.basket.items.length == 0">
                <img src="../../../assets/images/empty-cart.svg" alt="">
            </div>

            <div 
                class="section_products_list" 
                *ngIf="this.basketSerive.basket.items.length > 0"
                [ngStyle]="{'maxHeight': heightOfTheBasket}"
            >
                <div *ngFor="let product of this.basketSerive.basket.items; index as i" class="section_products_list-product">
                    <div class="section_products_list-product-details">    
                        <div class="section_products_list-product-details-img"><img [src]="product.imageUrl" alt=""></div>
                        <div class="section_products_list-product-details-designation_count">
                            <div class="section_products_list-product-details-designation_count-designation">{{ product.designation }}</div>
                            <div class="section_products_list-product-details-designation_count-count space-between">
                                <div class="section_products_list-product-details-designation_count-count">
                                    <div class="section_products_list-product-details-designation_count-count-remove" (click)="remove(i)">
                                        <mat-icon class="material-symbols-outlined">
                                            remove
                                        </mat-icon>
                                    </div>
                                    <div class="section_products_list-product-details-designation_count-count-number">
                                        {{ product.count }}
                                    </div>
                                    <div class="section_products_list-product-details-designation_count-count-add" (click)="add(i)">
                                        <mat-icon class="material-symbols-outlined">
                                            add
                                        </mat-icon>
                                    </div>
                                </div>

                                <div class="price">
                                    {{ getPriceTtc(product.prix, product.tva) | number : '1.2-2' }} Dhs
                                </div>
                                
                            </div>
                        </div>
                        <div class="section_products_list-product-details-add">
                            <mat-icon (click)="removeFromBasket(i)" class="material-symbols-outlined">
                                cancel
                            </mat-icon>
                        </div>
                    </div>
                    <!--
                        *ngIf="(this.basketSerive.basket.items.length - 1) != i"
                    -->
                    <hr class="section_products_list-product-divider">
                </div>
                <div class="section_products_list-total">Total : <span> {{ getTotal() | number : '1.2-2' }} Dhs</span></div>
            </div>

        </div>        
    </div>
    <!------------------------------------------------------------------------------------------------------------------------->

    <div 
        class="section_new_order" 
        *ngIf="listSuppliers.length > 0 && step == 3 && !stepsFinished[3]"
        [ngClass]="{'animate__animated animate__slideInRight duration-0-5': listSuppliers.length}"
        >
        <div class="section_new_order-choose_supplier">
            <div class="section_new_order-choose_supplier-step_title">
                <span class="section_new_order-choose_supplier-step_title-step_number">ETAPE 3 : </span>
                <span class="section_new_order-choose_supplier-step_title-step_order">Choisissez une boutique</span>
            </div>

            <mat-form-field appearance="outline" >
                <mat-select formControlName="caisse">
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let option of listCaisses" [value]="option">
                    {{option.nom_boutique}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

        </div>        
    </div>

</form>

<div 
    class="section_new_order-stepper_buttons" 
    *ngIf="listSuppliers.length > 0"
    [ngClass]="{'animate__animated animate__slideInRight duration-0-5': listSuppliers.length || step == 1}"
    > 
    <div 
        class="section_new_order-stepper_buttons-button btn" 
        (click)="previous()"
        [ngClass]="{'hideElement': !stepsFinished[1] || stepsFinished[3]}" 
        >Précédent</div>
    <div 
        class="section_new_order-stepper_buttons-button btn" 
        (click)="next()"
        [ngClass]="{'hideElement': stepsFinished[2]}" 
        >Suivant</div>
    <div 
        class="section_new_order-stepper_buttons-button btn" 
        (click)="validate()"
        [ngClass]="{'deleteElement': !stepsFinished[2] || stepsFinished[3]}"
        >
            <mat-icon class="material-symbols-outlined">
                shopping_cart_checkout
            </mat-icon>
    </div>
</div>
