<section class="section_header"
>
    <div class="section_header-arrow_back" (click)="goBack()">
        <mat-icon class="material-symbols-outlined">
            arrow_back
        </mat-icon>
    </div>
    <div class="section_header-title">
        {{ title }}
    </div>
    <div class="section_header-logout" (click)="logout()">
        <mat-icon class="material-symbols-outlined">
            logout
        </mat-icon>
    </div>
</section>